/* eslint-disable react-hooks/exhaustive-deps */

import { TextField, Select } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SingleSelector } from "../SelectComponents";
import { getCategoriesDtv, getReasonsByCategoryId } from "../../services/CategoryService";
import { getClients } from "../../services/ClientsService";
import { getSampleTypes } from "../../services/SampleTypesService";
import { getDepartments} from "../../services/DepartmentsService";
import { getBySection } from "../../services/LookupService";
import Multiselect from "multiselect-react-dropdown";
import Input from "@material-ui/core/Input";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import { TextareaAutosize } from "@mui/material";


function AddSweepJobPageOne(props) {

    const { shown, sweepJob, setSweepJob, setSaveBtnDisabled, page, setPage } = props;

    const [category, setCategory] = useState({id: -1});
    const [name, setName] = useState("");
    const [dueDate, setDueDate] = useState(null);

    const [markets, setMarkets] = useState([]);
    const [brands, setBrands] = useState([]);
    const [subBrands, setSubBrands] = useState([]);
    const [sectors, setSectors] = useState([]);
    const [sizes, setSizes] = useState([]);
    const [categories, setCategories] = useState([]);

    const [notes, setNotes] = useState("");

    const [allMarkets, setAllMarkets] = useState([]);
    const [allBrands, setAllBrands] = useState([]);
    const [allSubBrands, setAllSubBrands] = useState([]);
    const [allSectors, setAllSectors] = useState([]);
    const [allSizes, setAllSizes] = useState([]);
    const [allCategories, setAllCategories] = useState([]);

   
    const { t } = useTranslation();

    const sections = ["MARKET", "BRAND", "SECTOR", "SIZE", "SUBBRAND"];

    const useStyles = makeStyles((theme) => ({
        root: {
            flex: 1,
            backgroundColor: "paper",
        },
        dialog: {
            width: "400px",
            display: "flex",
        },
        dialogBox: {
            alignItems: "center",
            display: "flex",
        },
        dialogText: {
            flex: "0.4",
        },
        dialogInput: {
            flex: "0.5",
        },
        chips: {
            display: "flex",
            flexWrap: "wrap",
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        formControl: {
            minWidth: 120,
            maxWidth: 300,
        },
        testContainer: {
            display: "flex",
            columnGap: "5%",
            flexWrap: "wrap",
            alignItems: "center",
            width: "100%",
            rowGap: "0.5rem",
        },
    }));

    const classes = useStyles();


    useEffect(() => {
        if (shown) {
            getBySection(sections[0]).then((res) => {
                console.log("GOT MARKET: ", res);
                setAllMarkets(res);

                setSaveBtnDisabled(false);
            });

            getBySection(sections[1]).then((res) => {
                console.log("GOT BRAND: ", res);
                setAllBrands(res);

                setSaveBtnDisabled(false);
            });

            getBySection(sections[2]).then((res) => {
                console.log("GOT SECTOR: ", res);
                setAllSectors(res);

                setSaveBtnDisabled(false);
            });

            getBySection(sections[3]).then((res) => {
                console.log("GOT SIZE: ", res);
                setAllSizes(res);

                setSaveBtnDisabled(false);
            });

            getBySection(sections[4]).then((res) => {
                console.log("GOT SUBBRANDS: ", res);
                setAllSubBrands(res);

                setSaveBtnDisabled(false);
            });

            getCategoriesDtv().then((res) => {
                console.log("GOT categories: ", res);
                setAllCategories(res);

                setSaveBtnDisabled(false);
            });
        }
    }, [shown])


   

    useEffect(() => {
        if (sweepJob) {
            if (categories.length === 0 && sweepJob.categories && sweepJob.categories.length > 0) {
                setCategories(sweepJob.categories);
            }

            if (markets.length === 0 && sweepJob.markets && sweepJob.markets.length > 0) {
                setMarkets(sweepJob.markets);
            }

            if (brands.length === 0 && sweepJob.brands && sweepJob.brands.length > 0) {
                setBrands(sweepJob.brands);
            }

            if (subBrands.length === 0 && sweepJob.subBrands && sweepJob.subBrands.length > 0) {
                setSubBrands(sweepJob.subBrands);
            }

            if (sectors.length === 0 && sweepJob.sectors && sweepJob.sectors.length > 0) {
                setSectors(sweepJob.sectors);
            }

            if (sizes.length === 0 && sweepJob.sizes && sweepJob.sizes.length > 0) {
                setSizes(sweepJob.sizes);
            }

            if (sweepJob.name) {
                setName(sweepJob.name);
            }

            if (sweepJob.dueDate) {
                setDueDate(sweepJob.dueDate);
            }

            if (sweepJob.notes) {
                setNotes(sweepJob.notes);
            }

            if (!page) setPage(1)
        }

    }, [sweepJob])




   
    useEffect(() => {
        if (sectors && sectors.length > 0) {
            sweepJob.sectors = sectors;
            sweepJob.jobChanged = true;
            storeSweepJob();
        }
    }, [sectors]);

    useEffect(() => {
        if (markets && markets.length > 0) {
            sweepJob.markets = markets;
            sweepJob.jobChanged = true;
            storeSweepJob();
        }
    }, [markets]);

    useEffect(() => {
        if (categories && categories.length > 0) {
            sweepJob.categories = categories;
            sweepJob.jobChanged = true;
            storeSweepJob();
        }
    }, [categories]);

    useEffect(() => {
        if (brands && brands.length > 0) {
            sweepJob.brands = brands;
            sweepJob.jobChanged = true;
            storeSweepJob();
        }
    }, [brands]);

    useEffect(() => {
        if (subBrands && subBrands.length > 0) {
            sweepJob.subBrands = subBrands;
            sweepJob.jobChanged = true;
            storeSweepJob();
        }
    }, [subBrands]);

    useEffect(() => {
        if (sizes && sizes.length > 0) {
            sweepJob.sizes = sizes;
            sweepJob.jobChanged = true;
            storeSweepJob();
        }
    }, [sizes]);

    useEffect(() => {
        if (name) {
            sweepJob.name = name;
            sweepJob.jobChanged = true;
            storeSweepJob();
        }
    }, [name]);

    useEffect(() => {
        if (dueDate) {
            sweepJob.dueDate = dueDate;
            sweepJob.jobChanged = true;
            storeSweepJob();
        }
    }, [dueDate]);

    useEffect(() => {
        if (notes) {
            sweepJob.notes = notes;
            sweepJob.jobChanged = true;
            storeSweepJob();
        }
    }, [notes]);

    

    const handleDueDateChange = (date) => {
        setDueDate(date);
    };

  
    const handleSectorsChange = (event) => {
        setSectors(event.target.value)
    };

    const handleMarketsChange = (event) => {
        setMarkets(event.target.value)
    };

    const handleBrandsChange = (event) => {
        setBrands(event.target.value)
    };

    const handleSubBrandsChange = (event) => {
        setSubBrands(event.target.value)
    };

    const handleSizesChange = (event) => {
        setSizes(event.target.value)
    };

    const handleCategoriesChange = (event) => {
        setCategories(event.target.value)
    };

    const handleNameChange = (event) => {
        setName(event.target.value)
    };

    const handleNotesChange = (event) => {
        setNotes(event.target.value)
    };


    const storeSweepJob = () => {
        setSweepJob(JSON.parse(JSON.stringify(sweepJob)))
     }
   
    if (shown) return (
        <div>
            <h2>Sweep Job Details</h2>
            <table  style={{width:"100%"}}>
                <tr>
                    <td style={{ padding: "20px", verticalAlign: "top", display: "flex", flexDirection: "column"}}>                        
                        <div>Name:<span style={{ color: "red" }}>*</span> </div>
                        <TextField value={name} onChange={handleNameChange} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" required placeholder={t("Name...")} />

                        <div style={{ marginTop: "20px", marginBottom: "5px" }}>Due Date:<span style={{ color: "red" }}>*</span> </div>
                        
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label={"Set " + t("due_date_label")}
                                    value={sweepJob.dueDate}
                                    onChange={handleDueDateChange}      
                                    disablePast
                                    // InputProps={{style: {width: "20vw"}}}
                                    KeyboardButtonProps={{"aria-label": "change date",}}
                                />
                        </MuiPickersUtilsProvider>


                        <div style={{ marginTop: "20px", marginBottom: "5px" }}>Sector:<span style={{ color: "red" }}>*</span></div>

                        <Select
                            multiple
                            required
                            displayEmpty
                            // disabled={editDisabled}
                            value={sectors}
                            onChange={handleSectorsChange}
                            input={<Input />}
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <em style={{ color: "grey" }}>{t("Select all that apply")}...</em>;
                                }

                                return (
                                    <div className={classes.chips}>
                                        {selected.map((value) => (
                                            <Chip
                                                key={value.id}
                                                label={value.name}
                                                className={classes.chip}
                                            />
                                        ))}
                                    </div>
                                );
                            }}
                            inputProps={{ "aria-label": "Without label" }}>
                            {allSectors.map((equipment) => (
                                <MenuItem key={equipment.id} value={equipment}>
                                    {equipment.name}
                                </MenuItem>
                            ))}
                        </Select>

                        <div style={{ marginTop: "20px", marginBottom: "5px" }}>Market:<span style={{ color: "red" }}>*</span> </div>

                        <Select
                            multiple
                            required
                            displayEmpty
                            // disabled={editDisabled}
                            value={markets}
                            onChange={handleMarketsChange}
                            input={<Input />}
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <em style={{ color: "grey" }}>{t("Select all that apply")}...</em>;
                                }

                                return (
                                    <div className={classes.chips}>
                                        {selected.map((value) => (
                                            <Chip
                                                key={value.id}
                                                label={value.name}
                                                className={classes.chip}
                                            />
                                        ))}
                                    </div>
                                );
                            }}
                            inputProps={{ "aria-label": "Without label" }}>
                            {allMarkets.map((equipment) => (
                                <MenuItem key={equipment.id} value={equipment}>
                                    {equipment.name}
                                </MenuItem>
                            ))}
                        </Select>

                        <div style={{ marginTop: "20px", marginBottom: "5px" }}>Package Type:<span style={{ color: "red" }}>*</span> </div>

                        <Select
                            multiple
                            required
                            displayEmpty
                            // disabled={editDisabled}
                            value={categories}
                            onChange={handleCategoriesChange}
                            input={<Input />}
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <em style={{ color: "grey" }}>{t("Select all that apply")}...</em>;
                                }

                                return (
                                    <div className={classes.chips}>
                                        {selected.map((value) => (
                                            <Chip
                                                key={value.id}
                                                label={value.name}
                                                className={classes.chip}
                                            />
                                        ))}
                                    </div>
                                );
                            }}
                            inputProps={{ "aria-label": "Without label" }}>
                            {allCategories.map((equipment) => (
                                <MenuItem key={equipment.id} value={equipment}>
                                    {equipment.name}
                                </MenuItem>
                            ))}
                        </Select>

                        <div style={{ marginTop: "20px", marginBottom: "5px" }}>Own Brand:<span style={{ color: "red" }}>*</span> </div>

                        <Select
                            multiple
                            required
                            displayEmpty
                            // disabled={editDisabled}
                            value={brands}
                            onChange={handleBrandsChange}
                            input={<Input />}
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <em style={{ color: "grey" }}>{t("Select all that apply")}...</em>;
                                }

                                return (
                                    <div className={classes.chips}>
                                        {selected.map((value) => (
                                            <Chip
                                                key={value.id}
                                                label={value.name}
                                                className={classes.chip}
                                            />
                                        ))}
                                    </div>
                                );
                            }}
                            inputProps={{ "aria-label": "Without label" }}>
                            {allBrands.map((equipment) => (
                                <MenuItem key={equipment.id} value={equipment}>
                                    {equipment.name}
                                </MenuItem>
                            ))}
                        </Select>

                        <div style={{ marginTop: "20px", marginBottom: "5px" }}>Sub Brands:<span style={{ color: "red" }}>*</span> </div>

                        <Select
                            multiple
                            required
                            displayEmpty
                            // disabled={editDisabled}
                            value={subBrands}
                            onChange={handleSubBrandsChange}
                            input={<Input />}
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <em style={{ color: "grey" }}>{t("Select all that apply")}...</em>;
                                }

                                return (
                                    <div className={classes.chips}>
                                        {selected.map((value) => (
                                            <Chip
                                                key={value.id}
                                                label={value.name}
                                                className={classes.chip}
                                            />
                                        ))}
                                    </div>
                                );
                            }}
                            inputProps={{ "aria-label": "Without label" }}>
                            {allSubBrands.map((equipment) => (
                                <MenuItem key={equipment.id} value={equipment}>
                                    {equipment.name}
                                </MenuItem>
                            ))}
                        </Select>

                        <div style={{ marginTop: "20px", marginBottom: "5px" }}>Selection of Sizes:<span style={{ color: "red" }}>*</span> </div>

                        <Select
                            multiple
                            required
                            displayEmpty
                            // disabled={editDisabled}
                            value={sizes}
                            onChange={handleSizesChange}
                            input={<Input />}
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <em style={{ color: "grey" }}>{t("Select all that apply")}...</em>;
                                }

                                return (
                                    <div className={classes.chips}>
                                        {selected.map((value) => (
                                            <Chip
                                                key={value.id}
                                                label={value.name}
                                                className={classes.chip}
                                            />
                                        ))}
                                    </div>
                                );
                            }}
                            inputProps={{ "aria-label": "Without label" }}>
                            {allSizes.map((size) => (
                                <MenuItem key={size.id} value={size}>
                                    {size.name}
                                </MenuItem>
                            ))}
                        </Select>

                        <div style={{ marginTop: "20px", marginBottom: "5px" }}>Notes: </div>

                        <TextareaAutosize
                            value={notes}
                            onChange={handleNotesChange}
                            id="notes"
                            placeholder="Enter Notes..."
                        >
                        </TextareaAutosize>
                    </td>
                </tr>
            </table>
        </div>
    );
    else return <></>
}



export default AddSweepJobPageOne;





