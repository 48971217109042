import React, { useContext, useState, useEffect, useRef } from 'react';
import { Button } from '@material-ui/core';
import moment from "moment"
import { useTranslation } from 'react-i18next';
import DashIcon from '@mui/icons-material/HorizontalRule';
import { getSweepSetsByJob} from '../../services/RegisterJobService';
import { AlertContext } from '../Contexts/AlertContext/AlertContext';
import { makeStyles } from '@material-ui/core/styles';

export const parseDate = (date, format) => {
    if (date === null) return <span style={{ color: "lightgrey" }}><DashIcon /></span>
    try {
        let rtn = null;
        let temp = moment(date).format(format)
        if (temp !== "Invalid date") rtn = temp;
        if (rtn === null || rtn === "") rtn = <span style={{ color: "lightgrey" }}><DashIcon /></span>
        return rtn;
    }
    catch (err) {
        return <span style={{ color: "lightgrey" }}><DashIcon /></span>
    }
}


const SweepJobInfoTable = (props) => {
    const { t } = useTranslation();
    const { setSelectedSet, backToSummary } = props;
    const [job, setJob] = useState(null)
	const [sweepSets, setSweepSets] = useState([]);
    const [active, setActive] = useState(0);
	const [alertContext, setAlertContext] = useContext(AlertContext);


    const useStyles = makeStyles(theme => ({
        button: {
            opacity: 0.5,
        },
        buttonActive: {
            opacity: 1,
        }
    }));

    const classes = useStyles();

	const getSweepSets = (id) => {
		 getSweepSetsByJob(id).then((res) => {
	        if (res === false) {
	            setAlertContext({ ...alertContext, open: true });
	            return;
	        }
	        setSweepSets(res);

            setSelectedSet(res[0].id);
            setActive(res[0].sequence)
	    })
    };

    useEffect(() => {
		console.log(props, props.job);
        parseJob(JSON.parse(JSON.stringify(props.job)));
		
		getSweepSets(props.job.id);
    }, [])

    const parseDate = (date, format) => {
        if (date === null) return "N/A"
        try {
            let rtn = null;
            let temp = moment(date).format(format)
            if (temp !== "Invalid date") rtn = temp;
            if (rtn === null || rtn === "") rtn = "N/A"
            return rtn;
        }
        catch (err) {
            return "N/A"
        }
    }
    const getJobStates = () => {

        const states = {
            0: { text: t('state_received_label'), color: "#ADCBE5" },
            1: { text: t('state_inProgress_label'), color: "#F7C377" },
            2: { text: t('state_onHold_label'), color: "#F7C377"},
            3: { text: t('state_completedAccepted_label'), color: "#77C780" },
            4: { text: t('state_completedPending_label'), color: "#BEE8C4" },
            5: { text: t('state_overdue_label'), color: "#DD7677" },
        }
        return states;
    }

    const getProgress = (row) => {
        var progress = Math.floor((row.testsCompleted / (row.numberOfTests === null ? 0 : row.numberOfTests)) * 100)
        if (isNaN(progress)) progress = 0;
        return (progress)
    }


    const parseJob = (j) => {
       
        if (j.addedBy == null) j.addedBy = ""
        j.addDate = parseDate(j.addDate, 'DD/MM/YYYY')
        j.updatedAt = parseDate(j.updatedAt, 'DD/MM/YYYY @ HH:mm')
        j.dueDate = parseDate(j.dueDate, 'DD/MM/YYYY')

        if (j.client && j.client.name && (j.client.name !== null)) j.clientName = j.client.name
        else j.clientName = "<unknown>"
        setJob(j);
    }


    if (job !== null) return (
		<div>
        <table style={{ width: "100%" }}>
            <tbody>
                <tr>

                    <td>{t('Added by')}:<b> {job.updatedBy}</b></td>
                </tr>
                <tr>
                    <td>{t('Date added')}:<b> {job.addDate}</b></td>
                </tr>
                <tr>
                    <td>{t('Due date')}:<b> {job.dueDate}</b></td>
                </tr>
            </tbody>
        </table>
		<div width="100%" >
		<table width="100%" border="0" >
		<tbody>
			<tr>
			<td  >Set:</td>
			<div style={{display: "flex", flexDirection:"row"}}>
                {
                    sweepSets.map((tm) => {
                        return(
                            <td style={{margin:"2px"}}>
                                    <Button key={tm.id} variant="contained" className={active === tm.sequence ? classes.buttonActive : classes.button} onClick={() => {
                                        setSelectedSet(tm.id);
                                        setActive(tm.sequence);
                                        backToSummary();
                                    }}>
                                        {tm.sequence}
                                    </Button>
                            </td>
                            )
                        })
                }
            </div>
			</tr>
		</tbody>
		</table>
		
		</div>
		</div>)
    return null;
}

export default SweepJobInfoTable